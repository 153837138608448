import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
  ) { }

  open(url: string) {
    window.open(url);
    /*
    Browser.open({
      'url': url,
      'toolbarColor': window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')
    });
     */
  }
}
