import {Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RebatesService} from '../../services/rebates/rebates.service';
import {BrowserService} from '../../services/browser/browser.service';
import {UsersService} from '../../services/users/users.service';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';
import {LocationService} from '../../services/location/location.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Location} from '@angular/common';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {
  UserAccountsDwollaComponent
} from '../../components/bank-providers/dwolla/user-accounts-dwolla/user-accounts-dwolla.component';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {ToastComponent} from '../../components/toast/toast.component';
import {CryptoService} from '../../services/crypto/crypto.service';
import {VendorsService} from '../../services/vendors/vendors.service';
import {Platform} from '@ionic/angular';

declare var google;

@Component({
  selector: 'app-rebatedetail',
  templateUrl: './rebatedetail.page.html',
  styleUrls: ['./rebatedetail.page.scss'],
})
export class RebatedetailPage implements OnInit {

  @ViewChild('locationMapElement') mapElement: ElementRef;

  private map;

  private locationChunkSize = 3;
  private locationCount;
  private cloLocations;

  public offer;
  protected preferredOption;
  private offerType;
  protected redeemInfo;
  protected usesMessage;
  protected remaining;
  private userPaymentInfo;

  constructor(
      private route: ActivatedRoute,
      private rebatesService: RebatesService,
      private browserService: BrowserService,
      private usersService: UsersService,
      private locationService: LocationService,
      private router: Router,
      private location: Location,
      private commissionjunctionService: CommissionJunctionService,
      public domSanitizer: DomSanitizer,
      private uiAlertService: UiAlertService,
      private stripeService: StripeService,
      private toastComponent: ToastComponent,
      private cryptoService: CryptoService,
      private vendorsService: VendorsService,
      private platform: Platform
  ) {

  }

  ngOnInit() {
    this.stripeService.getUserPaymentInfo().then(result => this.userPaymentInfo = result);
    this.route.params.subscribe(async params => {
      this.offerType = params.type;
      switch (this.offerType) {
        case 'access':
          this.offer = this.rebatesService.getRebateDetails(params.id);
          const redeemOffer = this.offer._raw.links.redeem_offer['instore_print'];
          this.rebatesService.getOffer(params.id).then(offer => {
            this.remaining = offer.offer_uses_remaining;
          });
          this.rebatesService.getAccessContent(redeemOffer ? redeemOffer : this.offer._raw.links.redeem_offer.link).then(result => {
            this.redeemInfo = result.details;
          });
          break;
        case 'groupon':
          this.offer = this.rebatesService.getRebateDetails(params.id);
          // this.preferredOption = this.offer._raw.options[this.offer._raw.preferredOfferIndex];
          // this.setupMap();
          break;
        case 'clo':
          this.offer = await this.commissionjunctionService.getCLODetails(params.id);
          this.preferredOption = {
            redemptionLocations: this.offer.locations ? this.offer.locations.sort(function(a, b) {
              if (a.position < b.position) { return -1; } if (a.position > b.position) { return  1; } return 0;
            }) : [],
            details: [
              { description: this.offer.description }
            ]
          };
          this.setupMap();
          break;
        case 'cj':
          this.offer = this.commissionjunctionService.getCJRebateDetails(params.id);
          break;
      }
      if (this.offer.locationChunkSize) {
        this.locationChunkSize = parseInt(this.offer.locationChunkSize, 10);
      }
      this.locationCount = this.locationChunkSize;
      if (this.offerType === 'groupon') {
        this.cloLocations = this.preferredOption.redemptionLocations.slice(0, this.locationCount);
      }
    });
  }

  private setupMap() {
    const options = {
      center: {lat: this.locationService.defaultLocation.latitude, lng: this.locationService.defaultLocation.longitude},
      zoom: 11,
      streetViewControl: false
    };
    const _this = this;
    if (this.mapElement) {
      setTimeout(() => {
        _this.map = new google.maps.Map(_this.mapElement.nativeElement, options);
        _this.cloLocations.forEach(location => this.addMarker(location));
        _this.showAllLocations();
      }, 250);
    }
  }

  addMarker(location) {
    const latLng = new google.maps.LatLng(parseFloat(location.latitude ? location.latitude : location.lat), parseFloat(location.longitude ? location.longitude : location.lng));
    const markerOptions = {
      position: latLng,
      map: this.map
    }
    const marker = new google.maps.Marker(markerOptions);
    marker.addListener('click', () => {
      const address = `${location.streetAddress1}, ${location.city}, ${location.state} ${location.postalCode}`;
      if ((navigator.platform.indexOf('iPhone') !== -1) ||
            (navigator.platform.indexOf('iPad') !== -1) ||
            (navigator.platform.indexOf('iPod') !== -1)) {
        window.open(`maps://maps.google.com?q=${address}`);
      } else {
          window.open(`https://maps.google.com/maps/place/${address}`);
      }
    });
  }

  getDiscountPercentage() {
    if (this.offer.discount_type === 'percent') {
      return parseFloat(this.offer.discount_value).toFixed(0) + '% Off';
    }
    if (!this.offer.discount_percentage) {
      if (this.getStrikethroughPrice()) {
        const price = parseFloat(this.offer.price);
        const salePrice = parseFloat(this.offer['sale-price']);
        return (100 * (price - salePrice) / price).toFixed(0) + '% Off';
      } else {
        return '';
      }
    }
    const percentage = this.offer.discount_percentage * 100;
    return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}% Off`;
  }

  getDiscountAmount() {
    return this.offerType === 'access'  && this.offer.discount_type === 'amount' && parseFloat(this.offer.discount_value) !== 0 ? `$${parseFloat(this.offer.discount_value).toFixed(2)} Off` : '';
  }

  private currency(amount) {
    return parseFloat(amount).toFixed(2);
  }

  getStrikethroughPrice() {
    return this.offer['sale-price'] && this.offer['sale-price'] !== this.offer.price ? ('$' + this.currency(this.offer.price)) : '';
  }

  getCashback() {
    let percentage;
    if (this.offer.cashback_percentage) {
      percentage = this.offer.cashback_percentage;
    } else if (this.offer.cashBackPercentage) {
      percentage = this.offer.cashBackPercentage / 100;
    } else {
      if (this.offerType !== 'cj') {
        return '';
      }
      percentage = this.commissionjunctionService.getDefaultCashBack();
    }
    if (this.offer.price) {
      const cashback = this.offer.price * percentage;
      return `$${(cashback).toFixed(cashback < 10 ? 2 : 0)} Cash Back`;
    } else {
      return percentage === 0 ? '' : `${(percentage * 100).toFixed(percentage < 10 ? 1 : 0)}% Cash Back`;
    }
  }

  getAndWord(offer) {
    return this.getCashback() && this.getDiscountPercentage() ? ' and ' : '';
  }

  getDescription() {
    if (this.offer.details) {
      return this.domSanitizer.bypassSecurityTrustHtml(this.offer.details);
    }
    return this.domSanitizer.bypassSecurityTrustHtml(this.offerType === 'cj' || this.offerType === 'access' ? this.offer.description : this.preferredOption.details[0].description);
  }

  getImage() {
    return this.offerType === 'access' ? this.offer.image_url : (this.offer._raw ? this.offer._raw.grid4ImageUrl : this.offer['image-url']);
  }

  claimClicked() {
    switch (this.offerType) {
      case 'groupon':
        this.browserService.open(this.offer.url);
        break;
      case 'cj':
        this.browserService.open(this.offer['link'] + '?sid=' + this.usersService.getCurrentUserId());
        break;
      case 'clo':
        this.browserService.open(this.offer['buy-url'] + '?sid=' + this.usersService.getCurrentUserId());
        break;
    }
  }

  getRebateTypeInfo() {
    if (!this.offer.cashBackPercentage || this.offer.cashBackPercentage === '0') {
      return '';
    }
    return this.offerType === 'clo' ? 'Just go to a participating location and use your Wallit-connected debit card.' :
        'Just tap the claim button and pay using your Wallit-connected debit card and receive cash back in addition to the merchant discount.';
  }

  rebateCashback() {
    return 'Online Instant Cash Back';
  }

  rebateDiscount() {
    return 'Discount';
  }

  getLocations() {
    switch (this.offerType) {
      case 'clo':
        return this.cloLocations;
      case 'cj':
        return [];
      case 'groupon':
        return this.preferredOption.redemptionLocations;
      case 'access':
        return [this.offer.merchant];
    }
  }

  showAllLocations() {
    const bounds = new google.maps.LatLngBounds();
    let latLng;
    this.cloLocations.forEach(location => {
      latLng = new google.maps.LatLng(parseFloat(location.latitude ? location.latitude : location.lat), parseFloat(location.longitude ? location.longitude : location.lng));
      bounds.extend(latLng);
    });
    if (this.cloLocations.length < 2) {
      this.map.setCenter(latLng);
      this.map.setZoom(this.offer.map ? parseInt(this.offer.map.zoom, 10) : 11);
    } else {
      this.map.fitBounds(bounds);
    }
  }

  loadMoreClicked() {
    const newLocations = this.preferredOption.redemptionLocations.slice(this.locationCount, this.locationCount + this.locationChunkSize);
    this.locationCount += newLocations.length;
    this.cloLocations = this.cloLocations.concat(newLocations);
    newLocations.forEach(location => this.addMarker(location));
    this.showAllLocations();
  }

  expandLocations() {
    this.router.navigate(['/rebateslocal', this.offer.id], {replaceUrl: true});
  }

  private redeem(type) {
    const redeemOffer = this.offer._raw.links.redeem_offer[type];
    this.rebatesService.getAccessContent(redeemOffer ? redeemOffer : this.offer._raw.links.redeem_offer.link).then(result => {
      this.browserService.open(result.details.link);
    });
  }

  redeemStoreClicked() {
    this.redeem('instore');
  }

  redeemPrintClicked() {
    this.browserService.open(this.redeemInfo.link);
  }

  gotoUrl() {
    this.browserService.open(this.offer['external-url']);
  }

  private calculateTotalGraduatedPrice(quantity, priceTiers) {
    let total = 0;
    let remainingQuantity = quantity;
    for (const tier of priceTiers) {
      const { unit_amount, up_to } = tier;
      if (up_to === null || remainingQuantity <= up_to) {
        total += remainingQuantity * unit_amount;
        break;
      } else {
        total += up_to * unit_amount;
        remainingQuantity -= up_to;
      }
      if (tier.flat_amount) {
        total += tier.flat_amount;
      }
    }
    return total;
  }

  calculateTotalVolumePrice(quantity, priceTiers) {
    for (const tier of priceTiers) {
      const { unit_amount, up_to } = tier;
      if (up_to == null || up_to >= quantity) {
        return tier.unit_price * quantity;
      }
    }
  }

  purchaseByQuantity(productId, count, doneCallback = null): void {
    this.stripeService.getProduct(productId).then(product => {
      let totalPrice = 0;
      switch (product.price.billing_scheme) {
        case 'tiered':
          switch (product.price.tiers_mode) {
            case 'graduated':
              totalPrice = this.calculateTotalGraduatedPrice(count, product.price.tiers);
              break;
            case 'volume':
              totalPrice = this.calculateTotalVolumePrice(count, product.price.tiers);
              break;
          }
          break;
        case 'per_unit':
          totalPrice = product.price.unit_amount * count;
          break;
      }
      totalPrice /= 100;
      if (!this.userPaymentInfo.defaultPaymentMethod) {
        this.uiAlertService.presentAlertConfirm(`You must first set up a payment method.  If you have already linked your payment method under Accounts, you still need to link it here in order to authorize funds to be withdrawn. Set it up now?`).then(confirm => {
          if (confirm) {
            this.router.navigate(['/products']);
          }
        });
        return;
      }
      this.uiAlertService.presentAlertConfirm(`This will initiate a recurring charge of $${totalPrice.toFixed(2)} for ${count} item${count !== '1' ? 's' : ''}.<p>You may cancel your subscription at any time from the Products page.</p>Proceed?`).then(confirm => {
        if (confirm) {
          this.stripeService.purchaseSubscription(this.userPaymentInfo.stripeCustomerId, productId, count, true).then(result => {
            this.toastComponent.presentToast('Your product has been purchased and your payment method charged.');
            if (doneCallback) {
              doneCallback();
            } else {
              this.location.back();
            }
          }).catch(error => this.toastComponent.presentToast('Your product purchase failed.'));
        }
      });
    });
  }

  parseHandlebars(value): string {
    function buildDOMValuesDict(element) {
      const domValues = {};
      // Check if the element has an 'id' attribute
      if (element.id) {
        // If yes, store the value in the dictionary with the id as the key
        domValues[element.id] = element.value;
      }
      // Recursively process child elements
      for (let i = 0; i < element.children?.length; i++) {
        const child = element.children[i];
        const childValues = buildDOMValuesDict(child);
        Object.assign(domValues, childValues);
      }
      return domValues;
    }
    const template = Handlebars.compile(value);
    const namedTags = buildDOMValuesDict(document.getElementsByClassName('descriptionhtml')[0]);
    return(template({dom: namedTags}));
  }

  doneClicked(action) {
    switch (action.type) {
      case 'purchase':
        this.purchaseByQuantity(this.parseHandlebars(action.arguments.stripeProductId), this.parseHandlebars(action.arguments.quantity));
        break;
      case 'cancel':
        this.location.back();
        break;
      case 'appurl':
        let template = Handlebars.compile(action.arguments.url);
        this.router.navigate([template(this.usersService.me())]);
        break;
      case 'externalurl':
        template = Handlebars.compile(action.arguments.url);
        window.open(template(Object.assign({}, this.usersService.me(), {reward: this.offer }, {platform: this.platform.platforms()})));
        break;
      case 'embedded':
        template = Handlebars.compile(action.arguments.url);
        this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url: template(this.usersService.me())})]);
        break;
      case 'purchaseVendor':
        this.purchaseByQuantity(this.parseHandlebars(action.arguments.stripeProductId), this.parseHandlebars(action.arguments.quantity), _ => {
          this.vendorsService.getProductLaunchUrl(action.arguments.vendor, action.arguments.product, {arguments: action.arguments}).then(result => {
            if (result.errors) {
              this.toastComponent.presentToast(`${result.errors[0]}. Please add this information to your profile and try your purchase again.`);
            } else {
              this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url: result.url})]);
            }
          });
        });
        break;
      case 'vendor':
        this.vendorsService.getProductLaunchUrl(action.arguments.vendor, action.arguments.product, {arguments: action.arguments}).then(result => {
          if (result.errors) {
            this.toastComponent.presentToast(`${result.errors[0]}. Please add this information to your profile and try your purchase again.`);
          } else {
            this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url: result.url})]);
          }
        });
    }
  }

}

@Pipe({
  name: 'getDescription',
  pure: true
})
export class GetDescriptionPipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.getDescription();
  }

}

@Pipe({
  name: 'getAdvertiserName',
  pure: true
})
export class GetAdvertiserNamePipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.domSanitizer.bypassSecurityTrustHtml(thisArg.offer.merchant ? thisArg.offer.merchant.name : thisArg.offer['advertiser-name']);
  }

}

@Pipe({
  name: 'getTitle',
  pure: true
})
export class GetTitlePipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.domSanitizer.bypassSecurityTrustHtml(thisArg.offer.title);
  }

}
