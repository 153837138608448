import { Component, OnInit } from '@angular/core';
import {ToastController} from '@ionic/angular';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  constructor(
      private toastController: ToastController,
  ) { }

  ngOnInit() {
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 30000,
      cssClass: 'toastClass',
      buttons: [
        {
          text: 'X',
          handler: () => {
            toast.dismiss();
          }
        }
      ]
    });
    toast.onDidDismiss().then(_ => {
    });
    toast.present();
  }

  presentError(error): boolean {
    if (error && error.data && error.data.errors) {
      this.presentToast(error.data.errors);
      return true;
    }
    if (error && error.error) {
      this.presentToast(error.error.error || error.error.errors || error.error.display_message || error.error.error_message || error.error);
      return true;
    }
    console.log('unknown error', error);
    return false;
  }

}
