import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-beneficiaries',
  templateUrl: './insurance-beneficiaries.component.html',
  styleUrls: ['./insurance-beneficiaries.component.scss'],
})
export class InsuranceBeneficiariesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
