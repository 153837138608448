import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {RebatesService} from '../../services/rebates/rebates.service';
import {HttpClient} from '@angular/common/http';
import * as Mustache from 'mustache';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-rebates-api',
  templateUrl: './rebates-api.component.html',
  styleUrls: ['./rebates-api.component.scss'],
})
export class RebatesApiComponent implements OnInit {

  @Input() config;

  protected items;
  protected listStyle;

  constructor(
    private rebatesService: RebatesService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(
  ) {
    this.rebatesService.getRebatesFromApi(this.config.apiURL).then(items => {
      this.items = items;
    });
    if (this.config.height) {
      this.listStyle = {
        'max-height': this.config.height + 'px',
        'overflow-y': 'scroll'
      };
    }
  }

  itemClicked(item) {
      if (item.clickURL) {
          window.open(item.clickURL);
      }
  }

}

@Pipe({
    name: 'getcontent'
})
export class GetContentPipe implements PipeTransform {
    transform(item: any, thisArg: RebatesApiComponent) {
        const view = {
            row: item
        };
        return thisArg.sanitizer.bypassSecurityTrustHtml(Mustache.render(thisArg.config.template, view));
    }

}
