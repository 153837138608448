import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UsersService} from '../../services/users/users.service';
import {TeamsService} from '../../services/teams/teams.service';

@Component({
  selector: 'app-team-carousel',
  templateUrl: './team-carousel.component.html',
  styleUrls: ['./team-carousel.component.scss'],
})
export class TeamCarouselComponent implements OnInit, OnChanges {

  @Input() startIndex;
  @Output() slideChangedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('HomeSlides') slides;

  public isEnd;
  public isBeginning;
  public teams = [];

  private slideOpts = {
    spaceBetween: 10,
    watchOverflow: false,
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination',
      /*renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
        },*/
    }
  };

  constructor(
      private usersService: UsersService,
      private teamsService: TeamsService
  ) { }

  ngOnInit() {
    this.usersService.meSubscribe((data) => {
      this.teams = [];
      if (data) {
        this.teamsService.getUserMemberTeams(this.usersService.getCurrentUserId()).then(teams => {
          this.teams = teams.map(team => {
            return {
              balance: team.balance,
              accountName: `Allowance Balance`,
              accountLogo: undefined,
              accountDetails: team.name,
              left: {
                amount: team.reimbursedThisMonth,
                label: 'Reimbursed This Month'
              },
              right: {
                amount: team.amount,
                label: 'Monthly Allowance',
              }
            };
          });
          this.setPagerDots();
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.slides?.slideTo(this.startIndex);
  }

  private setPagerDots() {
    document.documentElement.style.setProperty('--swipervisibility',  this.teams.length === 0 ? 'none' : 'block');
    this.isEnd = this.teams.length >= 1;
  }

  leftArrow() {
    this.slides.slideNext();
  }

  rightArrow() {
    this.slides.slidePrev();
  }

  updateSliderIconState() {
    this.isEnd = this.slides.isEnd().then(isEnd => {
      this.isEnd = isEnd;
    });
    this.slides.isBeginning().then(isBeginning => {
      this.isBeginning = isBeginning;
    });
    this.slides.getActiveIndex().then(index => {
      this.slideChangedEvent.emit(index);
    });
  }

}
