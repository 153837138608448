import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(
    private restangular: Restangular,
    private usersService: UsersService,
  ) {
  }

  getUserMemberTeams(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('member').getList().toPromise();
  }

  getUserTeamInfo(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).get().toPromise();
  }

  getTeamInfo(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).get().toPromise();
  }

  getCategories(): Promise<any> {
    return this.restangular.one('teams').one('categories').get().toPromise();
  }

  getTeamMembers(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('members').getList().toPromise();
  }

  addTeamMember(teamId: string, userEmail, userId = null): Promise<any> {
    return this.restangular.one('teams', teamId).one('member').customPOST({
      userId: userId,
      userEmail: userEmail,
      balance: 0
    }).toPromise();

  }

  removeTeamMember(id: string): Promise<any> {
    return this.restangular.one('teams').one('member', id).remove().toPromise();
  }

  createTeam(userId: string, name: string): Promise<any> {
    return this.restangular.one('teams', userId).customPOST({
      name,
      categories: [],
      frequency: 'monthly',
      amount: 0,
      disabled: false,
      plan: 'Free'
    }).toPromise();
  }

  modifyTeam(teamId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).customPUT({
      ...fields
    }).toPromise();
  }

  modifyTeamMember(teamId: string, memberId: string, fields: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('member', memberId).customPUT({
      ...fields
    }).toPromise();
  }

  deleteTeam(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).remove().toPromise();
  }

  resendEmailToTeamMember(teamId: string): Promise<any> {
    return this.restangular.one('teams').one('member', teamId).one('resend').customPUT().toPromise();
  }

  setStripeSessionId(userId: string, sessionId: string): Promise<any> {
    return this.getUserTeamInfo(userId).then(info => {
      return this.restangular.one('teams', info.id).customPUT({
        stripeSessionId: sessionId
      });
    });
  }

  makeRecognitionTransfer(teamId: string, userId: string, amount: number, members: any, message: string): Promise<any> {
    return this.restangular.one('teams', teamId).one(userId).one('makerecognitiontransfer').customPOST({
      amount,
      members,
      message
    }).toPromise();
  }

  moveP2pTransfer(teamId: string, userId: string, amount: number): Promise<any> {
    return this.restangular.one('teams', teamId).one(userId).one('movep2ptransfer').customPOST({ amount }).toPromise();
  }

  uploadMemberList(teamId, data): Promise<any> {
    return this.restangular.one('teams', teamId).one('uploadmembers').customPUT(data, undefined, undefined, { 'content-type': 'text/html' }).toPromise();
  }

  buildMemberList(members: Array<any>, team: any): Array<any> {
    const newMembers: Array<any> = [];
    members.forEach((member: any, index: number) => {
      member.email = member.userEmail;
      member.isQr = true;
      if (!member.userId || !member.status) {
        if (member.status === 'active' || team.amount === 0) {
          member.message = team.amount === 0 ? 'Pending Invitation' : 'Pending Signup';
        } else {
          member.message = 'Awaiting Payment';
          newMembers.push(member.email);
        }
      }
      if (member.userId) {
        member.message = member.status === 'active' || team.amount === 0 ? 'Active' : 'Awaiting Payment';
        let me = false;
        if (member.userId === this.usersService.getCurrentUserId()) {
          if (!member.lastName) {
            member.lastName = '';
          }
          if (member.userId === team.ownerUserId && member.lastName.indexOf('(Admin)') < 0) {
            member.lastName += ' (Admin)';
          }
          if (member.lastName.indexOf('(Me)') < 0) {
            member.lastName += ' (Me)';
          }
          me = true;
        }
        if (me) {
          const thisMember = members[index];
          members.splice(index, 1);
          members.unshift(thisMember);
        }
      } else {
        member.avatar = 'no_avatar.png';
      }
    });
    return newMembers;
  }

  savePurchase(teamId: string, userId: string, purchase: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases').customPOST(purchase).toPromise();
  }

  updatePurchase(teamId: string, userId: string, purchaseId: string, purchase: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases', purchaseId).customPUT(purchase).toPromise();
  }

  removePurchase(teamId: string, userId: string, purchaseId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases', purchaseId).remove().toPromise();
  }

  setReceipt(teamId: string, userId: string, purchaseId: string, img: any): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases', purchaseId).all('receipt').customPUT(img, undefined, undefined, { 'content-type': 'image/*' }).toPromise();
  }

  getPurchases(teamId: string, userId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases').getList().toPromise();
  }

  getPurchase(teamId: string, userId: string, purchaseId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('user', userId).one('purchases', purchaseId).get().toPromise();
  }

  getActivity(userId: string): Promise<any> {
    return this.restangular.one('teams').one('user', userId).one('activity').customGET('', { messageBuild: true }).toPromise();
  }

  getP2pActivity(teamId: string): Promise<any> {
    return this.restangular.one('teams', teamId).one('p2prewards').getList().toPromise();
  }

}
